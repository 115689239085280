import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField UseSubFilter:in_make_mandatory:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAddPagesManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.toolManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAddPagesViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.toolManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnnotationToolbar</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 8.0.2. Use the PTToolGroupToolbar class instead</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTCollaborationDocumentViewController</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 8.0.2. Use the PTCollaborationDocumentController class instead</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentBaseViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.appSettingsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.appSettingsButtonHidden</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewControllerDelegate</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 8.0.2. Use the PTDocumentControllerDelegate protocol instead.</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 8.0.2. Use the PTDocumentController class instead. </td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolManager promptForBluetoothPermission]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.annotationsCreatedWithPencilOnly</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.pencilInteractionMode</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager.widgetAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *widgetAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTWidgetAnnotationOptions *widgetAnnotationOptions</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsSettingsFooterDescriptionKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsMultivalueKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsPlistNameKey</div>
<div class="difference"><span class="status added">Added</span> PTPencilInteractionMode</div>
<div class="difference"><span class="status added">Added</span> PTPencilInteractionModeFingerAndPencil</div>
<div class="difference"><span class="status added">Added</span> PTPencilInteractionModePencilOnly</div>
<div class="difference"><span class="status added">Added</span> PTPencilInteractionModeSystem</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.applePencilDefaultSettings</div>
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsManager defaultSettingsForPlistName:]</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.pencilInteractionMode</div>
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsManager integerForKey:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsManager setInteger:forKey:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsViewController initWithStyle:plistName:]</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsViewController.plistName</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsUtil.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTToolsUtil createPTPDFDocFromFromUIView:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTWidgetAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTWidgetAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTWidgetAnnotationOptions.preferredDatePickerStyle</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}